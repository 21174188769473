
import KirbyLink from './kirbylink.vue'
export default {
    name: "textblock",
    props:{
      content: Object
    },
    components:{
      KirbyLink
    }
}
